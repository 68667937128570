import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './plugins/axios';
import VueAxios from 'vue-axios';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import LaravelVuePagination from 'laravel-vue-pagination';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import dayjs from 'dayjs';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';


//import adminlte scripts
window.$ = window.jQuery = require("jquery");
import "../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js";
import "../node_modules/admin-lte/dist/js/adminlte.min.js";

//import adminlte styles
import '../node_modules/admin-lte/dist/css/adminlte.min.css';
import "../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
import "../node_modules/admin-lte/plugins/fontawesome-free/css/all.min.css";


const app = createApp(App)
app.component('pagination', LaravelVuePagination);
app.component('v-select', vSelect);

app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(VueToast,{
    position:'top-right',
});
app.use(FloatingVue);
app.use(SimpleTypeahead);
app.use(VCalendar);

app.config.globalProperties.$dayjs = dayjs;
app.mount('#app');
